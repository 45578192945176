import { tns } from "tiny-slider";
import { Fancybox } from "@fancyapps/ui";
document.addEventListener("DOMContentLoaded", () => {
  const buttons = document.querySelectorAll(".btn");
  const modal = document.querySelector(".modal");
  const closeButtons = document.querySelectorAll(".modal__close");
  const toggleModal = (classname, node) => {
    node.classList.toggle(`${classname}_active`);
  };
  const headerClose = document.querySelector(".header__close");
  const header = document.querySelector(".header");
  const modalThank = document.querySelector(".modal_thank");
  const modalMain = document.querySelector(".modal__main");
  const modalButton = modalThank.querySelector(".modal__button");

  const addEventToggle = (items, classname, nodeForToggling) => {
    items.forEach((item) => {
      if (!item.classList.contains("modal__button"))
        item.addEventListener("click", (e) => {
          toggleModal(classname, nodeForToggling);
        });
    });
  };
  modalButton.addEventListener("click", () => toggleModal("modal", modalThank));
  const burger = document.querySelector(".icon-menu.mobile");
  burger.addEventListener("click", () => {
    header.classList.add("header_mobile");
  });
  addEventToggle(buttons, "modal", modal);
  closeButtons[0].addEventListener("click", () => {
    toggleModal("modal", modal);
  });
  closeButtons[1].addEventListener("click", (e) => {
    toggleModal("modal", modalThank);
  });
  window.addEventListener("scroll", (e) => {
    if (window.scrollY > 700) header.classList.add("header_active");
    else header.classList.remove("header_active");
  });
  headerClose.addEventListener("click", (e) => {
    header.classList.remove("header_mobile");
  });
  modalMain.addEventListener("submit", async (e) => {
    e.preventDefault();
    let isError = false;
    let body = Object.fromEntries(new FormData(e.target).entries());
    const spinner = document.querySelector(".spinner");
    spinner.classList.add("loading");
    const thankText = modalThank.querySelector(".modal_thank-text");
    const text = modalThank.querySelector(".modal__form-text");
    try {
      let res = await fetch("https://ecostroy-plus.ru:3000/form", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (!res.ok) {
        text.textContent = "Произошла ошибка при отправке запроса";
        thankText.textContent = "Повторите попытку позже";
      }
    } catch {
      isError = true;
      text.textContent = "Произошла ошибка при отправке запроса";
      thankText.textContent = "Повторите попытку позже";
    }
    spinner.classList.remove("loading");
    modal.classList.remove("modal_active");
    modalThank.classList.add("modal_active");
  });

  var slider = tns({
    container: ".my-slider",
    items: 3,
    responsive: {
      319: {
        controls: false,
        items: 1,
      },
      567: {
        controls: false,
        items: 2,
      },
      992: {
        controls: false,
        items: 3,
      },
      1083: {
        controls: true,
      },
    },
    gutter: 30,
    slideBy: 1,
    autoplay: false,
    autoplayButton: false,
    autoplayButtonOutput: false,
    prevButton: ".left",
    nextButton: ".right",
    nav: false,
  });

  Fancybox.bind('[data-fancybox="gallery"]', {
    hideScrollbar: false,
  });
  Fancybox.bind('[data-fancybox="gallery-2"]', {
    hideScrollbar: false,
  });
  Fancybox.bind('[data-fancybox="gallery-3"]', {
    hideScrollbar: false,
  });
  Fancybox.bind('[data-fancybox="gallery-4"]', {
    hideScrollbar: false,
  });
  Fancybox.bind('[data-fancybox="gallery-5"]', {
    hideScrollbar: false,
  });
  Fancybox.bind('[data-fancybox="gallery-6"]', {
    hideScrollbar: false,
  });
});
